import BaseApi from "../Core/REST/BaseApi";
import Reminder from "./model/Reminder";
import { getSessionId } from "../Login/loginFunction";

class ReminderWebSocketService {
 socket? :WebSocket;
  async connect(clientId: number) {
    this.socket = new WebSocket(`${process.env.REACT_APP_WEB_SOCKET_ENV}/reminder-web-socket/${clientId}`)

    this.socket.addEventListener("open", event => {
        if(this.socket)
        this.socket.send("Connection established")
      });

  }
  async getRefreshReminders(clientId:number,setterFunction :(reminderArray:Reminder[])=>void)
  {
    if(this.socket === undefined)
    {
      getSessionId()
      await this.connect(clientId);
    }
    if(this.socket)
      this.socket.addEventListener("message", event => {

        var reminderArray:Reminder[] = JSON.parse(event.data);
        reminderArray.forEach(reminder => {
          if(reminder.date)
            reminder.date = new Date(reminder.date + "Z");
        });
        setterFunction(reminderArray);
      });
    }

}
const webSocketService = new ReminderWebSocketService();
export default webSocketService;
