import { Client } from "../../services/Users/Client";
import Popup from "../../components/Core/Popup";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import dateHelper from "../../services/Core/Helpers/date-helper";
import Reminder from "../../services/Reminder/model/Reminder";
import remindersService from "../../services/Reminder/ReminderService";
import { toast } from "react-toastify";
import Select from "react-select";
import { Dispatch, SetStateAction } from "react";
import { EmployeeCard } from "../../components/Cards/employee-card";
type Props = {
  user: Client;
  isOpen: boolean;
  closeAction: () => void;
  reminder?: Reminder;
};
export function ReminderFormPopup(props: Props) {


  const { t } = useTranslation();
  const reminderDefault = props.reminder ?? {
    priority: 3,
    assignedToId: props.user.id,
  };
  const [reminder, setReminder] = useState<Reminder>(reminderDefault);
  const [validated, setValidated] = useState(false);

  const creating = !props.reminder;
  const editable = reminder.creatorId === props.user.id || creating;
  return (
    <Popup
      onClose={props.closeAction}
      onOk={() => {
        setValidated(true);
        if (editable && reminderIsValid()) {
          if (creating)
            remindersService
              .createReminder(reminder)
              .then(() => {
                props.closeAction();
                toast.success(t("reminder-menu.create-success"));
              })
              .catch(() => {
                toast.error(t("reminder-menu.create-error"));
              });
          else
            remindersService
              .updateReminder(reminder)
              .then(() => {
                props.closeAction();
                toast.success(t("reminder-menu.update-success"));
              })
              .catch(() => {
                toast.error(t("reminder-menu.update-error"));
              });
          setReminder({});
          setValidated(false);
        }
      }}
      title={
        creating
          ? t("reminder-menu.create-reminder")
          : editable
          ? t("reminder-menu.edit-reminder")
          : t("reminder-menu.reminder")
      }
      show={props.isOpen}
    >
      <Form noValidate validated={validated} id="reminder-form">
        <div className="mb-3">
          {editable ? (
            <RemindableUserSelectInput
              reminder={reminder}
              setReminder={setReminder}
              disable={editable}
            ></RemindableUserSelectInput>
          ) : (
            <>
              <label>{t("reminder-menu.reminder-creator")}</label>
              <EmployeeCard employee={reminder.creator} />
            </>
          )}
        </div>
        <div className="mb-3">
          <label>{t("reminder-menu.date")}</label>
          <InputGroup hasValidation>
            <Form.Control
              id="date"
              aria-label="Date and time"
              type="datetime-local"
              disabled={!editable}
              className="input"
              required
              value={
                reminder?.date
                  ? dateHelper.toISOLocal(reminder.date)?.substring(0, 16)
                  : ""
              }
              size="sm"
              onChange={(ev) => {
                setValue("date", new Date(ev.target.value));
              }}
            />
          </InputGroup>
        </div>
        <div className="mb-3">
          <PrioritySwitchInput
            reminder={reminder}
            setReminder={setReminder}
            disable={editable}
          />
        </div>
        <div className="mb-3">
          <label>{t("reminder-menu.title")}</label>
          <InputGroup>
            <Form.Control
              id="title"
              className="input"
              required
              disabled={!editable}
              defaultValue={reminder.title}
              onChange={(ev) => {
                setValue("title", ev.target.value);
              }}
            />
          </InputGroup>
        </div>
        <div className="mb-3">
          <label>{t("reminder-menu.description")}</label>
          <InputGroup>
            <Form.Control
              id="description"
              as="textarea"
              className="input"
              disabled={!editable}
              rows={3}
              defaultValue={reminder.description}
              onChange={(ev) => {
                setValue("description", ev.target.value);
              }}
            />
          </InputGroup>
        </div>
      </Form>
    </Popup>
  );
  function setValue(key: string, value: any) {
    setReminder({ ...reminder, [key]: value } as Reminder);
  }
  function reminderIsValid() {
    return (
      reminder && reminder.title && reminder.assignedToId && reminder.priority
    );
  }
}
type InputProps = {
  reminder: Reminder;
  setReminder: Dispatch<SetStateAction<Reminder>>;
  disable: boolean;
};
function RemindableUserSelectInput({
  reminder,
  setReminder,
  disable,
}: InputProps) {
  const [remindableUserArray, setRemindableUserArray] = useState<Client[]>();
  const { t } = useTranslation();

  useEffect(() => {
    remindersService
      .getRemindableUser()
      .then((res) => setRemindableUserArray(res))
      .catch(() => toast.error(t("reminder-menu.get-remindable-user-error")));
  }, []);
  let options = remindableUserArray?.map((client) => ({
    value: client.id,
    label: client.nom,
    client: client,
  }));
  if (options === undefined) return <div></div>;
  return (
    <>
      <label htmlFor="assignedToId">{t("reminder-menu.assigned-to")}</label>
      <div className="row">
        <div className="col sm-6">
          <Select
            id="dynacomCustId"
            className="react-select-container"
            classNamePrefix="react-select"
            isDisabled={!disable}
            value={options.find((x) => x.value === reminder.assignedToId)}
            options={options}
            onChange={(ev) => {
              setReminder({
                ...reminder,
                assignedToId: ev?.value,
              });
            }}
          />
        </div>
        <div className="col sm-6">
          <EmployeeCard
            employee={
              options.find((x) => x.value === reminder.assignedToId)?.client
            }
          />
        </div>
      </div>
    </>
  );
}
function PrioritySwitchInput({ reminder, setReminder, disable }: InputProps) {
  const { t } = useTranslation();
  return (
    <>
      <label>{t("reminder-menu.priority")}</label>
      <div className="switch-container">
        <div className="switch-toggle switch-3 switch-candy">
          <div>
            <input
              checked={reminder.priority === 3}
              id="to-do"
              name="state-d"
              type="radio"
              readOnly
            />
            <label
              htmlFor="to-do"
              onClick={(ev) =>
                disable && setReminder({ ...reminder, priority: 3 })
              }
            >
              {t("reminder-menu.to-do")}
            </label>

            <input
              checked={reminder.priority === 2}
              id="important"
              name="state-d"
              readOnly
              type="radio"
            />
            <label
              htmlFor="important"
              className="disabled"
              onClick={(ev) =>
                disable && setReminder({ ...reminder, priority: 2 })
              }
            >
              {t("reminder-menu.important")}
            </label>

            <input
              checked={reminder.priority === 1}
              id="urgent"
              name="state-d"
              type="radio"
              readOnly
            />
            <label
              htmlFor="urgent"
              onClick={(ev) =>
                disable && setReminder({ ...reminder, priority: 1 })
              }
            >
              {t("reminder-menu.urgent")}
            </label>
          </div>
        </div>
      </div>
    </>
  );
}
