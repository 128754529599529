import { Client } from "../../services/Users/Client";
import { useTranslation } from "react-i18next";
import BreadcrumbComponent from "../../components/Core/BreadcrumbComponent";
import { useNavigate, useParams } from "react-router-dom";

import { useEffect, useMemo, useState } from "react";
import {
  SortingState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import useColumnVisibility from "../../hooks/React table/useColumnVisibility";
import Pagination from "../../components/react-table/Pagination";
import {
  ReactTableHeaderOptions,
  getTableHeaderSortProps,
} from "../../components/react-table/react-table-component";
import { ColumnVisibilityComponent } from "../../components/react-table/column-visibility-component";
import { Button, Col } from "react-bootstrap";
import {
  Page,
  PageDetails,
  PageHeader,
} from "../../components/Pages/page-components";
import nonConformityReportService from "../../services/NonConformity/NonConformityReportService";
import { NonConformityReport } from "../../services/NonConformity/Models/NonConformityReport";

type IParamTypes = {
  employeeId: string;
};

export default function NonConformityReportTable() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { employeeId } = useParams<IParamTypes>();
  const [globalFilter, setGlobalFilter] = useState("");
  const [nonConformityReportArray, setNonConformityReportArray] =
    useState<Array<NonConformityReport>>();
  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnVisibility, setColumnVisibility] = useState({});
  const pageTitle = t(
    "non-conformity-report-page.record-of-non-conformity-reports"
  );
  useEffect(() => {
    document.title = pageTitle;
    nonConformityReportService
      .getAllNonConformityReport()
      .then((data) => setNonConformityReportArray(data))
      .catch(() => t("non-conformity-report-page.non-confomity-report"));
  }, [employeeId]);
  const columns: any = useMemo(() => {
    return [
      {
        header: t("non-conformity-report-page.id"),
        accessorKey: "id",
      },
      {
        header: t("non-conformity-report-page.provenance"),
        cell: (props: any) => {
          let provenanceText =
            (props.row.original?.isProvenanceClient
              ? t("non-conformity-report-page.client") + ", "
              : "") +
            (props.row.original?.isProvenanceInternal
              ? t("non-conformity-report-page.internal") + ", "
              : "") +
            (props.row.original?.isProvenanceSupplier
              ? t("non-conformity-report-page.supplier") + ", "
              : "");
          provenanceText = provenanceText.slice(0, -2);
          return (
            <span>
              {provenanceText}
            </span>
          );
        },
      },
      {
        header: t("non-conformity-report-page.responsibility"),
        accessorKey: "isEmployeeResponsibility",
        cell: (props: any) => {
          let provenanceText =
            (props.row.original?.isEmployeeResponsibility
              ? t("non-conformity-report-page.employee") + ", "
              : "") +
            (props.row.original?.isClientResponsibility
              ? t("non-conformity-report-page.client") + ", "
              : "") +
            (props.row.original?.isOtherResponsibility
              ? t("non-conformity-report-page.other") + ", "
              : "") +
            (props.row.original?.isAdminResponsibility
              ? t("non-conformity-report-page.admin") + ", "
              : "") +
            (props.row.original?.isProductionResponsibility
              ? t("non-conformity-report-page.production") + ", "
              : "") +
            (props.row.original?.isConceptionResponsibility
              ? t("non-conformity-report-page.conception") + ", "
              : "") +
            (props.row.original?.isSupplierResponsibility
              ? t("non-conformity-report-page.supplier") + ", "
              : "");
          provenanceText = provenanceText.slice(0, -2);
          return (
            <span>
              {provenanceText}
            </span>
          );
        },
      },
      {
        header: t("non-conformity-report-page.description"),
        accessorKey: "description",
      },
      {
        header: t("non-conformity-report-page.creation-date"),
        accessorKey: "creationDate",
        cell: (props: any) => (
         <>{props.getValue()&&(props.getValue()as Date).toDateString()}</>   
        )
      },
      {
        header: t("non-conformity-report-page.close-date"),
        accessorKey: "closeDate",
        cell: (props: any) => (
            <>{props.getValue()&&(props.getValue()as Date).toDateString()}</>   
           )
      },
      {
        header: t(
          "non-conformity-report-page.cost-associated-with-the-complain"
        ),
        accessorKey: "costAssociatedWithTheComplain",
      },
    ];
  }, [nonConformityReportArray]);

  const table = useReactTable({
    columns,
    data: nonConformityReportArray ? nonConformityReportArray : [],
    enableColumnFilters: true,
    enableHiding: true,
    initialState: {
      columnVisibility: { status: false, "mrt-row-expand": true },
    },
    state: {
      globalFilter,
      columnVisibility,
      sorting,
    },
    onColumnVisibilityChange: setColumnVisibility,
    onGlobalFilterChange: setGlobalFilter,
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onSortingChange: setSorting,
    autoResetPageIndex: false,
  });

  const iColumnVisibility = useColumnVisibility(table, columnVisibility);

  const breadcrumbItems = [{ text: pageTitle, active: true }];
  return (
    <Page className="users-container">
      <PageHeader id="users-page-header" className="d-block">
        <BreadcrumbComponent items={breadcrumbItems} />
        <div className="d-flex">
          <h1 className="h3 mb-2">{pageTitle}</h1>
          <Button
            id="new-user-btn"
            variant="outline-primary"
            className="ms-3"
            onClick={() => navigate(`/non-conformity-report-form/${employeeId}`)}
          >
            {t("non-conformity-report-page.new-non-conformity-report")}
          </Button>
        </div>
      </PageHeader>

      <PageDetails className="pt-2">
        <div className="row mb-3">
          <Col sm={2}>
            <div className="input-group">
              <input
                type="search"
                className="form-control rounded"
                placeholder={t("common.search") || ""}
                aria-label={t("common.search") || ""}
                onChange={(ev) => {
                  setGlobalFilter(ev.target.value);
                  table.resetPageIndex(true);
                }}
              />
            </div>
          </Col>
        </div>

        <section id="users-table">
          <table className="table table-hover">
            <thead>
              <tr id="goal-table-options" className="table-options">
                <th
                  colSpan={
                    table.getAllFlatColumns().filter((x) => x.getIsVisible())
                      .length
                  }
                >
                  <ColumnVisibilityComponent
                    columns={iColumnVisibility}
                    className="mx-1"
                  />
                </th>
              </tr>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                    return (
                      <th key={header.id}>
                        <span {...getTableHeaderSortProps(header.column)}>
                          {header.column.columnDef.header?.toString()}
                          <ReactTableHeaderOptions header={header} />
                        </span>
                      </th>
                    );
                  })}
                </tr>
              ))}
            </thead>
            <tbody>
              {table.getRowModel().rows.map((row) => {
                return (
                  <tr key={row.id}>
                    {row.getVisibleCells().map((cell) => {
                      return (
                        <td
                          className="align-vertical-center"
                          key={cell.id}
                          onClick={() => {
                            navigate(`/non-conformity-report-form/${employeeId}/${row.original.id}`)
                          }}
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
          <Pagination
            reactTable={table}
            siblingCount={2}
            dataName={
              t("non-conformity-report-page.non-confomity-report") ?? ""
            }
            showDataLength
          />
        </section>
      </PageDetails>
    </Page>
  );
}
