
import BaseApi from "../Core/REST/BaseApi";
import { NonConformityReport } from "./Models/NonConformityReport";


class NonConformityReportService extends BaseApi {

  async getNonConformityReport(
    externalFileId: string,
  ) : Promise<NonConformityReport | undefined>{

    let url = `${this.baseUrl}/NonConformityReport/${externalFileId}`;
    var nonConfomityReport : NonConformityReport|undefined = await this.get(url);
    if(nonConfomityReport)
    {
      if(nonConfomityReport.creationDate)
        nonConfomityReport.creationDate = new Date(nonConfomityReport.creationDate);
      if(nonConfomityReport.closeDate)
        nonConfomityReport.closeDate = new Date(nonConfomityReport.closeDate);
    }

    return nonConfomityReport;
  }
  async getAllNonConformityReport(
  ) : Promise<Array<NonConformityReport>|undefined>{

    let url = `${this.baseUrl}/NonConformityReport/getAll`;
    var nonConfomityReportArray : Array<NonConformityReport>|undefined = await this.get(url);
    if(nonConfomityReportArray)
    {
      nonConfomityReportArray.forEach(nonConfomityReport => {
        if(nonConfomityReport.creationDate)
          nonConfomityReport.creationDate = new Date(nonConfomityReport.creationDate);
        if(nonConfomityReport.closeDate)
          nonConfomityReport.closeDate = new Date(nonConfomityReport.closeDate);
      });

    }
    return nonConfomityReportArray;
  }
  async saveNonConformityReport(nonConformityReport:NonConformityReport,employeeId:string)
  {
    let url = `${this.baseUrl}/NonConformityReport/Save-Non-Conformity-Report/${employeeId}`;
    return await this.post(url,nonConformityReport);
  }
  async validateNonConformityReport(nonConformityReport:NonConformityReport,employeeId:string)
  {
    let url = `${this.baseUrl}/NonConformityReport/Validate-Non-Conformity-Report/${employeeId}`;
    return await this.post(url,nonConformityReport);
  }
}

const nonConformityReport = new NonConformityReportService();
export default nonConformityReport;
