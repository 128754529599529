import "./menu-component.scss";
import {
  IconDefinition,
  faBars,
  faChartLine,
  faClock,
  faCoins,
  faCube,
  faFileInvoiceDollar,
  faFolderOpen,
  faGear,
  faPenToSquare,
  faReceipt,
  faUsers,
  faCalculator,
  faLock,
  faHashtag
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Client } from "../../../services/Users/Client";
import CheckAllowedComponent from "../../CheckAllowedComponent";
import { useNavigate } from "react-router-dom";
import stringHelper from "../../../services/Core/Helpers/string-helper";
type Props = {
  user: Client;
  id?: string;
};

export default function MenuComponent({user,id="side-menu"}:Props) {
  const env: string = process.env.REACT_APP_ENV || "";
  let employeeId = user.id.toString();
  return (
    <div id={id}>
      {/* <SideMenuItem
        icon={faHouse}
        text={"Home"}
        linkPath={process.env.REACT_APP_HOME_PAGE_URL}
      /> */}

    {env === "development" && (
      <>
      <SideMenuItem
        icon={faBars}
        text={"Main menu"}
        linkPath={process.env.REACT_APP_MAIN_MENU_URL}
      />
      <SideMenuItem icon={faChartLine} text={"Dashboard"} linkPath={`/Project-Dashboard`} />
      <SideMenuItem icon={faPenToSquare} text={"Quotes"} linkPath={`/Quotes/${employeeId}`} />
      <SideMenuItem icon={faCube} text={"Engineering"} linkPath={`https://portal.flofab.com/Main/Production/Pages/DashboardEng.aspx?action=Login&u=flofab&a=yes`} />
      <SideMenuItem icon={faFolderOpen} text={"Projects"} linkPath={`/Projects/${employeeId}`} />
      <SideMenuItem icon={faFileInvoiceDollar} text={"Sales"} linkPath={`/Orders/${employeeId}`} />
      <SideMenuItem icon={faClock} text={"Production"} linkPath={`https://portal.flofab.com/ASPToASPNET.asp?destpage=/Main/Production/Pages/Orders.aspx?c=7`} />
      <SideMenuItem icon={faReceipt} text={"Invoicing"} linkPath={`/PageNotFound`} />
      <SideMenuItem icon={faCalculator} text={"PHE Selection"} linkPath={`https://soncalc.com/`} />
      <SideMenuItem icon={faCoins} text={"Goal"} linkPath={`/Production/Goal/${employeeId}`} />
      <SideMenuItem icon={faGear} text={"Email Config"} linkPath={`/EmailConfig/${employeeId}`} />
      <SideMenuItem icon={faUsers} text={"Manage Users"} linkPath={`/ManageUsers/${employeeId}`} />
      <CheckAllowedComponent user={user} objectName={"manage-permission"}>
            <SideMenuItem
              icon={faLock}
              text={"Manage Permissions"}
              linkPath={`/ManagePermission`}
            />
          </CheckAllowedComponent>
          <SideMenuItem icon={faHashtag} text={"Part Number"} linkPath={`/supplier-part-number-page`} />
      </>
    )}

    {env === "release" && (
      <>
      <SideMenuItem
        icon={faBars}
        text={"Main menu"}
        linkPath={process.env.REACT_APP_MAIN_MENU_URL}
      />
      {/* not safe to use this is just fo now */}
      <SideMenuItem icon={faChartLine} text={"Dashboard"} linkPath={`/Project-Dashboard`} />
      <SideMenuItem icon={faPenToSquare} text={"Quotes"} linkPath={`/Quotes/${employeeId}`} />
      <SideMenuItem icon={faCube} text={"Engineering"} linkPath={`https://portal.flofab.com/Main/Production/Pages/DashboardEng.aspx?action=Login&u=flofab&a=yes`} />
      <SideMenuItem icon={faFolderOpen} text={"Projects"} linkPath={`/Projects/${employeeId}`} />
      <SideMenuItem icon={faFileInvoiceDollar} text={"Sales"} linkPath={`/Orders/${employeeId}`} />
      <SideMenuItem icon={faClock} text={"Production"} linkPath={`https://portal.flofab.com/ASPToASPNET.asp?destpage=/Main/Production/Pages/Orders.aspx?c=7`} />
      <SideMenuItem icon={faReceipt} text={"Invoicing"} linkPath={`/PageNotFound`} />
      <SideMenuItem icon={faCalculator} text={"PHE Selection"} linkPath={`https://soncalc.com/`} />
      <SideMenuItem icon={faCoins} text={"Goal"} linkPath={`/Production/Goal/${employeeId}`} />
      <SideMenuItem icon={faGear} text={"Email Config"} linkPath={`/EmailConfig/${employeeId}`} />
      <SideMenuItem icon={faUsers} text={"Manage Users"} linkPath={`/ManageUsers/${employeeId}`} />
      <CheckAllowedComponent user={user} objectName={"manage-permission"}>
            <SideMenuItem
              icon={faLock}
              text={"Manage Permissions"}
              linkPath={`/ManagePermission`}
            />
          </CheckAllowedComponent>
          <SideMenuItem icon={faHashtag} text={"Part Number"} linkPath={`/supplier-part-number-page`} />
      </>
    )}
   
      
    </div>
  );
}

type SideMenuItemProps = {
  icon: IconDefinition;
  text: React.ReactNode;
  linkPath?: string;
};

function SideMenuItem(props: SideMenuItemProps) {
  let completeURL = stringHelper.RegExpUrl.test(props.linkPath ?? "");
  var extras:any = {};
  if (completeURL) { extras.href = props.linkPath; }

  const active = props.linkPath===window.location.pathname ? "active":"";
  const navigate = useNavigate();
  return (
    <a className={"side-menu-item " + active} title={props.text+""} {...extras} onClick={()=>{!completeURL && navigate(props.linkPath ?? "/")}}>
      <div className="icon-item text-center">
        <FontAwesomeIcon size="lg" icon={props.icon} />
      </div>
      <div className="text-item">{props.text}</div>
    </a>
  );
}
