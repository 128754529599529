type Props = {
  items: Array<BreadcrumbItem>;
};

type BreadcrumbItem = {
  text: string;
  link?: string;
  active?: boolean;
};

export default function BreadcrumbComponent(props: Props) {
  return (
    <nav aria-label="breadcrumb">
      <ol className="breadcrumb  no-print">
        <li key="home" className="breadcrumb-item">
          <a href="https://portal.flofab.com/HomeSite/Default/Pages/Default">
            Home
          </a>
        </li>
        {props.items.map((item, i) => {
          let className = `breadcrumb-item ${item.active ? "active" : ""}`;
          return (
            <li key={i} className={className}>
              {item.link ? <a href={item.link}>{item.text}</a> : item.text}
            </li>
          );
        })}
      </ol>
    </nav>
  );
}
