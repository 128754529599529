import "./assembly-control-page.scss";
import { useState, FormEvent } from "react";
import { Dispatch, SetStateAction } from "react";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";
import { Client } from "../../services/Users/Client";
import { Page } from "../../components/Pages/page-components";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import stringHelper from "../../services/Core/Helpers/string-helper";
import Select from "react-select";
import {
  faCheck,
  faFloppyDisk,
  faPrint,
} from "@fortawesome/free-solid-svg-icons";
import LogoComponent from "../../components/app/LogoComponent";
import userService from "../../services/Users/UserService";
import { AssemblyControl } from "../../services/AssemblyControl/Models/AssemblyControl";
import assemblyControlService from "../../services/AssemblyControl/AssemblyControlService";
import pumpService from "../../services/Pumps/PumpService";
import { Pump } from "../../services/Pumps/Models/Pump";
type IParamTypes = {
  externalFileId: string;
  employeeId: string;
};

function AssemblyControlPage() {
  const GestionnaireRole = 2;
  const { externalFileId, employeeId } = useParams<IParamTypes>();
  const [employee, setEmployee] = useState<Client>();
  const [assemblyControl, setAssemblyControl] = useState<AssemblyControl>();
  const [validated, setValidated] = useState(false);
  const isFinish: boolean = assemblyControl?.testDate != undefined;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (
      event.currentTarget.checkValidity() === true &&
      assemblyControl &&
      employeeId
    ) {
      assemblyControlService
        .ValidateAssemblyControl(assemblyControl, employeeId)
        .then(() => {
          toast.success(t("assembly-control-page.success-validating"));
          loadAssemblyControl();
        })
        .catch(() => {
          toast.error(t(`assembly-control-page.error-validating`));
        });
    } else setValidated(true);
  };
  useEffect(() => {
    if (externalFileId && employeeId) {
      loadAssemblyControl();
    }
  }, [externalFileId]);
  useEffect(() => {
    if (employeeId) {
      loadUser(employeeId);
    }
  }, [employeeId]);

  return (
    <Page id="assembly-control-page">
      <div className="only-print">
        <LogoComponent />
      </div>
      <div className="card mt-2">
        <div className="card-header">
          <h2 className="text-center">
            {t("assembly-control-page.assembly-control")}
          </h2>
        </div>
        <div className="card-body">
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <div className="quoteInformationContainer">
              <label>{t("assembly-control-page.creation-date")}: </label>
              <span>
                {assemblyControl?.creationDate
                  ? stringHelper.toDateString(assemblyControl.creationDate)
                  : stringHelper.toDateString(new Date())}
              </span>
              <label>{t("assembly-control-page.job-number")}:</label>
              <span>{assemblyControl?.jobNumber}</span>
              <label>{t("assembly-control-page.customer")}:</label>
              <span>{assemblyControl?.customer}</span>
              <label>{t("assembly-control-page.project")}:</label>
              <span>{assemblyControl?.project}</span>
              <label>{t("assembly-control-page.reference")}:</label>
              <span>{assemblyControl?.reference}</span>
              <label>{t("assembly-control-page.motor-model")}:</label>
              <InputGroup hasValidation>
                <Form.Control
                  id="necessaryMotorModel"
                  required
                  defaultValue={assemblyControl?.necessaryMotorModel}
                  size="sm"
                  onChange={(ev) => {
                    setValue("necessaryMotorModel", ev.target.value);
                  }}
                />
              </InputGroup>
              <InputGroup hasValidation className="assemblyTypeContainer">
                <Form.Check
                  id="assemblyTypeTriplex"
                  required
                  reverse
                  checked={assemblyControl?.assemblyType === "triplex"}
                  type="radio"
                  name="assemblyType"
                  label="Triplex"
                  value="triplex"
                  onChange={(ev) => {
                    setValue("assemblyType", ev.target.value);
                  }}
                />
                <Form.Check
                  id="assemblyTypeDuplex"
                  required
                  reverse
                  checked={assemblyControl?.assemblyType === "duplex"}
                  type="radio"
                  label="Duplex"
                  name="assemblyType"
                  value="duplex"
                  onChange={(ev) => {
                    setValue("assemblyType", ev.target.value);
                  }}
                />
                <Form.Check
                  id="assemblyTypeSimplex"
                  required
                  reverse
                  checked={assemblyControl?.assemblyType === "simplex"}
                  type="radio"
                  label="Simplex"
                  name="assemblyType"
                  value="simplex"
                  onChange={(ev) => {
                    setValue("assemblyType", ev.target.value);
                  }}
                />
                <Form.Check
                  id="assemblyTypeother"
                  required
                  reverse
                  checked={assemblyControl?.assemblyType === "other"}
                  label="Other"
                  type="radio"
                  name="assemblyType"
                  value="other"
                  onChange={(ev) => {
                    setValue("assemblyType", ev.target.value);
                  }}
                />
              </InputGroup>
              <PumpModelSelectInput
                assemblyControl={assemblyControl}
                setAssemblyControl={setAssemblyControl}
              />
            </div>
            <div className="card mt-2">
              <div className="card-header textInputContainer">
                <b className="d-flex">
                  {t("assembly-control-page.operations")}
                </b>
                <div className="d-flex justify-content-around">
                  <b>{t("assembly-control-page.valid")}</b>
                  <b>{t("assembly-control-page.invalid")}</b>
                </div>
              </div>
              <div className="card-body">
                <Row className="operationContainer">
                  <Col sm={12} className="textInputContainer">
                    <div className="selectInputContainer">
                      <label htmlFor="CorrectDrawing">
                        {t("assembly-control-page.correct-drawing")}
                      </label>
                      <InputGroup hasValidation>
                        <Form.Control
                          id="refDrawing"
                          required
                          defaultValue={assemblyControl?.refDrawing}
                          onChange={(ev) => {
                            setValue("refDrawing", ev.target.value);
                          }}
                        />
                      </InputGroup>
                    </div>
                    <ValidInvalidFormCheck
                      value={assemblyControl?.correctDrawing}
                      setValue={(newValue) => {
                        setValue("correctDrawing", newValue);
                      }}
                    />
                  </Col>
                  <Col sm={12} className="textInputContainer">
                    <div>
                      <label htmlFor="CorrectMaterial">
                        {t("assembly-control-page.correct-material")}
                      </label>
                      <div>{t("assembly-control-page.serial-number")}</div>
                      <div className="selectInputContainer">
                        <label>{t("assembly-control-page.motor")}</label>
                        <InputGroup hasValidation>
                          <Form.Control
                            required
                            id="UsedMotorModel"
                            defaultValue={assemblyControl?.usedMotorModel}
                            onChange={(ev) => {
                              setValue("usedMotorModel", ev.target.value);
                            }}
                          />
                        </InputGroup>
                      </div>
                      <div className="selectInputContainer">
                        <UsedPumpSelectInput
                          assemblyControl={assemblyControl}
                          setAssemblyControl={setAssemblyControl}
                        />
                      </div>
                    </div>
                    <ValidInvalidFormCheck
                      value={assemblyControl?.correctMaterial}
                      setValue={(newValue) => {
                        setValue("correctMaterial", newValue);
                      }}
                    />
                  </Col>
                  <Col sm={12} className="textInputContainer">
                    <label htmlFor="PointZeroCalculated">
                      {t("assembly-control-page.point-zero-calculated")}
                    </label>
                    <ValidInvalidFormCheck
                      value={assemblyControl?.pointZeroCalculated}
                      setValue={(newValue) => {
                        setValue("pointZeroCalculated", newValue);
                      }}
                    />
                  </Col>
                  <Col sm={12} className="textInputContainer">
                    <label htmlFor="MainComponentInstalled">
                      {t("assembly-control-page.main-component-installed")}
                    </label>
                    <ValidInvalidFormCheck
                      value={assemblyControl?.mainComponentInstalled}
                      setValue={(newValue) => {
                        setValue("mainComponentInstalled", newValue);
                      }}
                    />
                  </Col>
                  <Col sm={12} className="textInputContainer">
                    <label htmlFor="SecondaryComponentInstalled">
                      {t("assembly-control-page.secondary-component-installed")}
                    </label>
                    <ValidInvalidFormCheck
                      value={assemblyControl?.secondaryComponentInstalled}
                      setValue={(newValue) => {
                        setValue("secondaryComponentInstalled", newValue);
                      }}
                    />
                  </Col>
                  <Col sm={12} className="textInputContainer">
                    <label htmlFor="BoltComponentMarked">
                      {t("assembly-control-page.bolt-component-marked")}
                    </label>
                    <ValidInvalidFormCheck
                      value={assemblyControl?.boltComponentMarked}
                      setValue={(newValue) => {
                        setValue("boltComponentMarked", newValue);
                      }}
                    />
                  </Col>
                  <Col sm={12} className="textInputContainer">
                    <div>
                      <div className="d-flex">
                        <label htmlFor="HydrostaticTestDone">
                          {t("assembly-control-page.hydrotest-at")}
                        </label>
                        <InputGroup>
                          <Form.Control
                            required
                            placeholder={
                              assemblyControl?.requiredTestPressurePSI
                                ? assemblyControl.requiredTestPressurePSI +
                                  t("assembly-control-page.enter-to-confirm")
                                : ""
                            }
                            id="Psi"
                            type="number"
                            defaultValue={assemblyControl?.psi}
                            onChange={(ev) => {
                              setValue("psi", ev.target.value);
                            }}
                          />
                        </InputGroup>
                        <span>psi</span>
                      </div>
                      <div className="d-flex">
                        <span>{t("assembly-control-page.gauge-no")}</span>
                        <InputGroup>
                          <Form.Control
                            required
                            id="GaugeNo"
                            type="switch"
                            defaultValue={assemblyControl?.gaugeNo}
                            onChange={(ev) => {
                              setValue("gaugeNo", ev.target.value);
                            }}
                          />
                        </InputGroup>
                        <a
                          className="HydrostaticTestReportLink"
                          onClick={() =>
                            window.open(
                              `${window.location.origin}/HydrostaticTestReport/${assemblyControl?.quoteExternalFileId}/${employeeId}`
                            )
                          }
                        >
                          {t("assembly-control-page.(see-page)")}
                        </a>
                      </div>
                    </div>
                    <ValidInvalidFormCheck
                      value={assemblyControl?.hydrostaticTestDone}
                      setValue={(newValue) => {
                        setValue("hydrostaticTestDone", newValue);
                      }}
                    />
                  </Col>
                  <Col sm={12} className="textInputContainer">
                    <label htmlFor="AllPartAndAccessoriesInstalled">
                      {t(
                        "assembly-control-page.all-part-and-accessories-installed"
                      )}
                    </label>
                    <ValidInvalidFormCheck
                      value={assemblyControl?.allPartAndAccessoriesInstalled}
                      setValue={(newValue) => {
                        setValue("allPartAndAccessoriesInstalled", newValue);
                      }}
                    />
                  </Col>
                  <Col sm={12} className="textInputContainer">
                    <label htmlFor="VisualInspection">
                      {t("assembly-control-page.visual-inspection")}
                    </label>
                    <ValidInvalidFormCheck
                      value={assemblyControl?.visualInspection}
                      setValue={(newValue) => {
                        setValue("visualInspection", newValue);
                      }}
                    />
                  </Col>
                  <Col sm={12} className="textInputContainer">
                    <label htmlFor="SystemCleaned">
                      {t("assembly-control-page.system-cleaned")}
                    </label>
                    <ValidInvalidFormCheck
                      value={assemblyControl?.systemCleaned}
                      setValue={(newValue) => {
                        setValue("systemCleaned", newValue);
                      }}
                    />
                  </Col>
                  <Col sm={12} className="textInputContainer">
                    <label htmlFor="PictureBeforePackaging">
                      {t("assembly-control-page.picture-before-packaging")}
                    </label>
                    <ValidInvalidFormCheck
                      value={assemblyControl?.pictureBeforePackaging}
                      setValue={(newValue) => {
                        setValue("pictureBeforePackaging", newValue);
                      }}
                    />
                  </Col>
                  <Col sm={12} className="textInputContainer">
                    <label htmlFor="IsQCOk">
                      {t("assembly-control-page.QC-OK")}
                    </label>
                    <ValidInvalidFormCheck
                      value={assemblyControl?.isQCOk}
                      setValue={(newValue) => {
                        setValue("isQCOk", newValue);
                      }}
                    />
                  </Col>
                </Row>
              </div>
            </div>
            <div className="card mt-2">
              <div className="card-header">
                {t("assembly-control-page.job-issues")}
              </div>

              <div className="card-body no-print">
                <label>{t("assembly-control-page.note")}</label>
                <InputGroup>
                  <Form.Control
                    id="note"
                    as="textarea"
                    rows={2}
                    defaultValue={assemblyControl?.note}
                    onChange={(ev) => {
                      setValue("note", ev.target.value);
                    }}
                  />
                </InputGroup>
              </div>
              <div className="card-body only-print">
                <label>{t("assembly-control-page.note")}</label>
                <div>{assemblyControl?.note}</div>
              </div>
            </div>
            <div className="card mt-2">
              <div className="card-body">
                <Row>
                  <Col sm={6} className="d-flex justify-content-between">
                    <label>{t("assembly-control-page.technician")}</label>
                    {(assemblyControl?.technicianUserName ? assemblyControl?.technicianUserName :
              "") + (employee?.nom === assemblyControl?.technicianUserName
                ? ""
                : t("hydrostatic-test-report-page.save-to-become-tester", {
                    technicianName: employee?.nom,
                  }))}
                  </Col>
                  <Col sm={6} className="d-flex justify-content-between">
                    <label>{t("assembly-control-page.supervisor")}</label>
                    {assemblyControl?.supervisorUserName
                      ? assemblyControl.supervisorUserName
                      : employee?.roleId && employee?.roleId <= GestionnaireRole
                      ? employee.nom +
                        t("assembly-control-page.validate-to-confirm")
                      : t("assembly-control-page.supervisor-must-validate")}
                  </Col>
                  <Col sm={6} className="d-flex justify-content-between">
                    <label>{t("assembly-control-page.date-tested")}</label>
                    {isFinish
                      ? stringHelper.toDateString(assemblyControl?.testDate)
                      : employee?.roleId && employee?.roleId <= GestionnaireRole
                      ? stringHelper.toDateString(new Date()) +
                        t("assembly-control-page.validate-to-confirm")
                      : t("assembly-control-page.supervisor-must-validate")}
                  </Col>
                </Row>
              </div>
            </div>
            <div className="no-print">
              <div className="d-flex text-center mt-3">
                <div className="flex-fill">
                  <button
                    type="button"
                    className="btn btn-outline-primary"
                    onClick={() => window.print()}
                  >
                    <FontAwesomeIcon
                      size="xl"
                      className="me-2"
                      icon={faPrint}
                    />
                    {t("common.print")}
                  </button>
                </div>
                <div className="flex-fill">
                  <button
                    type="button"
                    className="btn btn-outline-primary"
                    onClick={() => {
                      if (assemblyControl && employeeId) {
                        assemblyControlService
                          .SaveAssemblyControl(assemblyControl, employeeId)
                          .then(() =>
                            toast.success(
                              t("assembly-control-page.success-saving")
                            )
                          )
                          .catch(() =>
                            toast.error(t("assembly-control-page.error-saving"))
                          );
                      }
                    }}
                  >
                    <FontAwesomeIcon
                      size="xl"
                      className="me-2"
                      icon={faFloppyDisk}
                    />
                    {t("common.save")}
                  </button>
                </div>
                {!assemblyControl?.testDate &&
                  employee?.roleId &&
                  employee?.roleId <= GestionnaireRole && (
                    <div className="flex-fill">
                      <button className="btn btn-outline-success" type="submit">
                        <FontAwesomeIcon
                          size="xl"
                          className="me-2"
                          icon={faCheck}
                        />
                        {t("assembly-control-page.validate")}
                      </button>
                    </div>
                  )}
              </div>
            </div>
          </Form>
        </div>
      </div>
    </Page>
  );
  function loadAssemblyControl() {
    if (externalFileId)
      assemblyControlService
        .GetAssemblyControl(externalFileId)
        .then((res) => {
          setAssemblyControl(res);
        })
        .catch(() => {
          toast.error(t("assembly-control-page.get-assembly-control-error"));
        });
  }
  function loadUser(employeeId: string) {
    userService
      .getUser(employeeId)
      .then((data) => {
        if (data) {
          setEmployee(data);
        }
      })
      .catch(() => {
        toast.error(t("user-form-page.load-error"));
      });
  }
  function setValue(key: string, value: any) {
    setAssemblyControl({ ...assemblyControl, [key]: value } as AssemblyControl);
  }
}
type AssemblyControlFormComponentsProps = {
  assemblyControl: AssemblyControl | undefined;
  setAssemblyControl: Dispatch<SetStateAction<AssemblyControl | undefined>>;
};
function PumpModelSelectInput({
  assemblyControl,
  setAssemblyControl,
}: AssemblyControlFormComponentsProps) {
  const { t } = useTranslation();
  useEffect(() => {
    pumpService
      .getAllPumps()
      .then((res) => setPumpArray(res))
      .catch(() => toast.error(t("assembly-control-page.get-pump-list-error")));
  }, []);
  const [pumpArray, setPumpArray] = useState<Array<Pump>>();

  let options = pumpArray?.map((pump) => ({
    value: pump.id,
    label: pump.modelNo,
  }));

  if (options === undefined || assemblyControl === undefined) return <div />;
  return (
    <>
      <label htmlFor="pumpId">{t("assembly-control-page.pump-model")}</label>
      <Select
        id="necessaryPumpId"
        className="react-select-container"
        classNamePrefix={
          (assemblyControl.necessaryPumpId ? "valid" : "invalid") +
          " react-select"
        }
        value={options.find((x) => x.value === assemblyControl.necessaryPumpId)}
        options={options}
        onChange={(ev) => {
          setAssemblyControl({
            ...assemblyControl,
            necessaryPumpId: ev?.value,
          });
        }}
      />
    </>
  );
}
function UsedPumpSelectInput({
  assemblyControl,
  setAssemblyControl,
}: AssemblyControlFormComponentsProps) {
  const { t } = useTranslation();
  useEffect(() => {
    pumpService
      .getAllPumps()
      .then((res) => setPumpArray(res))
      .catch(() => toast.error(t("assembly-control-page.get-pump-list-error")));
  }, []);
  const [pumpArray, setPumpArray] = useState<Array<Pump>>();

  let options = pumpArray?.map((pump) => ({
    value: pump.id,
    label: pump.modelNo,
  }));

  if (options === undefined || assemblyControl === undefined) return <div />;
  return (
    <>
      <label htmlFor="pumpId">{t("assembly-control-page.pump")}</label>
      <Select
        id="usedPumpId"
        className="react-select-container"
        classNamePrefix={
          (assemblyControl.usedPumpId ? "valid" : "invalid") + " react-select"
        }
        value={options.find((x) => x.value === assemblyControl.usedPumpId)}
        options={options}
        onChange={(ev) => {
          setAssemblyControl({
            ...assemblyControl,
            usedPumpId: ev?.value,
          });
        }}
      />
    </>
  );
}
type ValidInvalidFormCheckComponentsProps = {
  value: boolean | undefined;
  setValue: (newValue: boolean | undefined) => void;
};
function ValidInvalidFormCheck({
  value,
  setValue,
}: ValidInvalidFormCheckComponentsProps) {
  return (
    <div className="validInvalidCheckboxContainer">
      <InputGroup>
        <Form.Check
          required
          checked={value == true}
          onChange={() => {
            let newValue = value ? undefined : true;
            setValue(newValue);
          }}
        />
      </InputGroup>
      <InputGroup>
        <Form.Check
          className="x-checkbox"
          checked={value === false}
          onChange={() => {
            let newValue = value == false ? undefined : false;
            setValue(newValue);
          }}
        />
      </InputGroup>
    </div>
  );
}
export default AssemblyControlPage;
