import "./non-conformity-report-form.scss";
import { useState, FormEvent } from "react";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";
import { Client } from "../../services/Users/Client";
import { Page } from "../../components/Pages/page-components";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import stringHelper from "../../services/Core/Helpers/string-helper";
import Select from "react-select";
import {
  faCheck,
  faFloppyDisk,
  faPrint,
} from "@fortawesome/free-solid-svg-icons";
import LogoComponent from "../../components/app/LogoComponent";
import userService from "../../services/Users/UserService";
import { NonConformityReport } from "../../services/NonConformity/Models/NonConformityReport";
import nonConformityReportService from "../../services/NonConformity/NonConformityReportService";
import BreadcrumbComponent from "../../components/Core/BreadcrumbComponent";
type IParamTypes = {
  employeeId: string;
  reportId: string;
};

function NonConformityReportForm() {
  const GestionnaireRole = 2;
  const { employeeId, reportId } = useParams<IParamTypes>();
  const [employee, setEmployee] = useState<Client>();
  const navigate = useNavigate();
  const [nonConformityReport, setNonConformityReport] =
    useState<NonConformityReport>();
  const [validated, setValidated] = useState(false);
  const isFinish: boolean = nonConformityReport?.closeDate != undefined;
  const { t } = useTranslation();
  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (
      event.currentTarget.checkValidity() === true &&
      nonConformityReport &&
      employeeId
    ) {
      nonConformityReportService
        .validateNonConformityReport(nonConformityReport, employeeId)
        .then(() => {
          toast.success(t("non-conformity-report-page.success-validating"));
          loadNonConformityReport();
        })
        .catch(() => {
          toast.error(t(`non-conformity-report-page.error-validating`));
        });
    } else setValidated(true);
  };
  useEffect(() => {
    if (reportId && employeeId) {
      loadNonConformityReport();
    }
  }, []);
  useEffect(() => {
    if (employeeId) {
      loadUser(employeeId);
    }
  }, [employeeId]);

  return (
    <Page id="non-conformity-report-form" className="d-flex flex-column">
      <div className="only-print">
        <LogoComponent />
      </div>

      <BreadcrumbComponent
        items={[
          {
            text: t(
              "non-conformity-report-page.record-of-non-conformity-reports"
            ),
            link: `/non-conformity-report-table/${employeeId}`,
          },
          {
            text: t("non-conformity-report-page.non-conformity-report"),
            active: true,
          },
        ]}
      />

      <div className="card mt-2">
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <div className="card-header">
            <h3>{t("non-conformity-report-page.non-conformity-report")}</h3>
            <h5>
              <Row>
                <Col sm={4}>
                  {t("non-conformity-report-page.approved-by")} : John Chuprun
                </Col>
                <Col sm={3}>
                  {t("non-conformity-report-page.form-date")} : 2024-04-25
                </Col>
                <Col sm={3}>No. : F-09.1</Col>
                <Col sm={2}>{t("non-conformity-report-page.revision")} : 1</Col>
              </Row>
            </h5>
          </div>

          <div className="card-body">
            <div className="quoteInformationContainer">
              <Row>
                <Col sm={12}>
                  <h5 className="d-flex justify-content-center clientComplainInput">
                    <ValidInvalidFormCheck
                      value={!nonConformityReport?.isClientComplain}
                      setValue={(newValue) => {
                        setValue("isClientComplain", !newValue);
                      }}
                      trueLabel={t(
                        "non-conformity-report-page.non-conformity-report"
                      )}
                      falseLabel={t(
                        "non-conformity-report-page.client-complaint"
                      )}
                      id="isClientComplainCheck"
                    ></ValidInvalidFormCheck>
                  </h5>
                </Col>
                <Col className="mb-3" sm={6}>
                <b>
                    {t("non-conformity-report-page.report-number")}:
                    </b>
                  <span>
                    {nonConformityReport?.id
                      ? nonConformityReport.id
                      : t("non-conformity-report-page.(new-report)")}
                  </span>
                </Col>
                <Col sm={6}>
                    <b>{t("non-conformity-report-page.creation-date")}:</b>
                  <span>
                    {nonConformityReport?.creationDate
                      ? stringHelper.toDateString(
                          nonConformityReport.creationDate
                        )
                      : stringHelper.toDateString(new Date())}
                  </span>
                </Col>
              </Row>
              <Row>
                <Col sm={6}>
                  <InputGroup
                    hasValidation
                    id="Provenance"
                    className="assemblyTypeContainer"
                  >
                    <b>{t("non-conformity-report-page.provenance")}:</b>
                    <Form.Check
                      id="isProvenanceClient"
                      reverse
                      checked={nonConformityReport?.isProvenanceClient}
                      label={t("non-conformity-report-page.client")}
                      onChange={(ev) => {
                        setValue(
                          "isProvenanceClient",
                          !nonConformityReport?.isProvenanceClient
                        );
                      }}
                    />
                    <Form.Check
                      id="isProvenanceInternal"
                      reverse
                      checked={nonConformityReport?.isProvenanceInternal}
                      label={t("non-conformity-report-page.internal")}
                      onChange={(ev) => {
                        setValue(
                          "isProvenanceInternal",
                          !nonConformityReport?.isProvenanceInternal
                        );
                      }}
                    />
                    <Form.Check
                      id="isProvenanceSupplier"
                      reverse
                      checked={nonConformityReport?.isProvenanceSupplier}
                      label={t("non-conformity-report-page.supplier")}
                      name="assemblyType"
                      onChange={(ev) => {
                        setValue(
                          "isProvenanceSupplier",
                          !nonConformityReport?.isProvenanceSupplier
                        );
                      }}
                    />
                  </InputGroup>
                </Col>
                <Col sm={6}>
                  <InputGroup
                    hasValidation
                    id="Cause"
                    className="assemblyTypeContainer"
                  >
                    <b>{t("non-conformity-report-page.responsibility")}:</b>
                    <Form.Check
                      id="isEmployeeResponsibility"
                      reverse
                      checked={nonConformityReport?.isEmployeeResponsibility}
                      label={t("non-conformity-report-page.employee")}
                      onChange={(ev) => {
                        setValue(
                          "isEmployeeResponsibility",
                          !nonConformityReport?.isEmployeeResponsibility
                        );
                      }}
                    />
                    <Form.Check
                      id="isClientResponsibility"
                      reverse
                      checked={nonConformityReport?.isClientResponsibility}
                      label={t("non-conformity-report-page.client")}
                      onChange={(ev) => {
                        setValue(
                          "isClientResponsibility",
                          !nonConformityReport?.isClientResponsibility
                        );
                      }}
                    />
                    <Form.Check
                      id="isOtherResponsibility"
                      reverse
                      checked={nonConformityReport?.isOtherResponsibility}
                      label={t("non-conformity-report-page.other")}
                      onChange={(ev) => {
                        setValue(
                          "isOtherResponsibility",
                          !nonConformityReport?.isOtherResponsibility
                        );
                      }}
                    />
                    <Form.Check
                      id="isAdminResponsibility"
                      reverse
                      checked={nonConformityReport?.isAdminResponsibility}
                      label={t("non-conformity-report-page.admin")}
                      onChange={(ev) => {
                        setValue(
                          "isAdminResponsibility",
                          !nonConformityReport?.isAdminResponsibility
                        );
                      }}
                    />
                    <Form.Check
                      id="isProductionResponsibility"
                      reverse
                      checked={nonConformityReport?.isProductionResponsibility}
                      label={t("non-conformity-report-page.production")}
                      onChange={(ev) => {
                        setValue(
                          "isProductionResponsibility",
                          !nonConformityReport?.isProductionResponsibility
                        );
                      }}
                    />
                    <Form.Check
                      id="isConceptionResponsibility"
                      reverse
                      checked={nonConformityReport?.isConceptionResponsibility}
                      label={t("non-conformity-report-page.conception")}
                      onChange={(ev) => {
                        setValue(
                          "isConceptionResponsibility",
                          !nonConformityReport?.isConceptionResponsibility
                        );
                      }}
                    />
                    <Form.Check
                      id="isSupplierResponsibility"
                      reverse
                      checked={nonConformityReport?.isSupplierResponsibility}
                      label={t("non-conformity-report-page.supplier")}
                      onChange={(ev) => {
                        setValue(
                          "isSupplierResponsibility",
                          !nonConformityReport?.isSupplierResponsibility
                        );
                      }}
                    />
                  </InputGroup>
                </Col>
              </Row>
            </div>
            <Row id="generalInformations" className="operationContainer">
              <Col sm={12} className="textInputContainer">
                <div className="selectInputContainer">
                  <label htmlFor="textInputContainer">
                    <b>
                    {t("non-conformity-report-page.general-information")}
                    </b>
                  </label>
                  <InputGroup hasValidation>
                    <Form.Control
                      id="generalInformation"
                      maxLength={2000}
                      as="textarea"
                      rows={2}
                      required
                      defaultValue={nonConformityReport?.generalInformation}
                      onChange={(ev) => {
                        setValue("generalInformation", ev.target.value);
                      }}
                    />
                  </InputGroup>
                </div>
              </Col>
              <Col sm={12} className="textInputContainer">
                <div className="selectInputContainer">
                  <label htmlFor="textInputContainer">
                    <b>
                    {t("non-conformity-report-page.description")}
                    </b>
                  </label>
                  <InputGroup hasValidation>
                    <Form.Control
                      id="description"
                      maxLength={2000}
                      as="textarea"
                      rows={2}
                      required
                      defaultValue={nonConformityReport?.description}
                      onChange={(ev) => {
                        setValue("description", ev.target.value);
                      }}
                    />
                  </InputGroup>
                </div>
              </Col>
            </Row>
            <div  id="cause" className="fieldset-container">
            <fieldset>
              <legend>
                {t("non-conformity-report-page.cause")}
              </legend>
                <Row className="operationContainer m-1">
                  <Col sm={12}>
                    <InputGroup>
                      <Form.Check
                        id="isCauseWorker"
                        reverse
                        checked={nonConformityReport?.isCauseWorker}
                        label={t("non-conformity-report-page.worker")}
                        onChange={(ev) => {
                          setValue(
                            "isCauseWorker",
                            !nonConformityReport?.isCauseWorker
                          );
                        }}
                      />

                      <Form.Check
                        id="isCauseMethod"
                        reverse
                        checked={nonConformityReport?.isCauseMethod}
                        label={t("non-conformity-report-page.method")}
                        onChange={(ev) => {
                          setValue(
                            "isCauseMethod",
                            !nonConformityReport?.isCauseMethod
                          );
                        }}
                      />
                      <Form.Check
                        id="isCauseMaterial"
                        reverse
                        checked={nonConformityReport?.isCauseMaterial}
                        label={t("non-conformity-report-page.material")}
                        onChange={(ev) => {
                          setValue(
                            "isCauseMaterial",
                            !nonConformityReport?.isCauseMaterial
                          );
                        }}
                      />
                      <Form.Check
                        id="isCauseEngine"
                        reverse
                        checked={nonConformityReport?.isCauseEngine}
                        label={t("non-conformity-report-page.engine")}
                        onChange={(ev) => {
                          setValue(
                            "isCauseEngine",
                            !nonConformityReport?.isCauseEngine
                          );
                        }}
                      />
                      <Form.Check
                        id="isCausePlace"
                        reverse
                        checked={nonConformityReport?.isCausePlace}
                        label={t("non-conformity-report-page.place")}
                        onChange={(ev) => {
                          setValue(
                            "isCausePlace",
                            !nonConformityReport?.isCausePlace
                          );
                        }}
                      />
                    </InputGroup>
                  </Col>
                  <Col sm={12} className="textInputContainer">
                    <div className="d-flex flex-column align-items-start">
                      <label htmlFor="causeExplication">
                      <b>
                        {t("non-conformity-report-page.explain")}
                        </b>
                      </label>
                      <InputGroup hasValidation>
                        <Form.Control
                          id="causeExplication"
                          as="textarea"
                          rows={2}
                          defaultValue={nonConformityReport?.causeExplication}
                          onChange={(ev) => {
                            setValue("causeExplication", ev.target.value);
                          }}
                        />
                      </InputGroup>
                    </div>
                  </Col>
                </Row>
            </fieldset>
            </div>

            <div id="immediateTreatment" className="fieldset-container">
              <fieldset>
              <legend>
                {t("non-conformity-report-page.immediate-treatment")}
              </legend>
              <div className="m-1">
                <InputGroup hasValidation className="assemblyTypeContainer">
                  <Form.Check
                    id="immediateTreatmentUseAsIs"
                    required
                    checked={
                      nonConformityReport?.immediateTreatment === "use as is"
                    }
                    type="radio"
                    name="immediateTreatment"
                    label={t("non-conformity-report-page.use-as-is")}
                    value="use as is"
                    onChange={(ev) => {
                      setValue("immediateTreatment", ev.target.value);
                    }}
                  />
                  <Form.Check
                    id="immediateTreatmentReturnToSupplier"
                    required
                    checked={
                      nonConformityReport?.immediateTreatment ===
                      "return to supplier"
                    }
                    type="radio"
                    name="immediateTreatment"
                    label={t("non-conformity-report-page.return-to-supplier")}
                    value="return to supplier"
                    onChange={(ev) => {
                      setValue("immediateTreatment", ev.target.value);
                    }}
                  />
                  <Form.Check
                    id="immediateTreatmentScrap"
                    required
                    checked={
                      nonConformityReport?.immediateTreatment === "scrap"
                    }
                    type="radio"
                    name="immediateTreatment"
                    label={t("non-conformity-report-page.scrap")}
                    value="scrap"
                    onChange={(ev) => {
                      setValue("immediateTreatment", ev.target.value);
                    }}
                  />
                  <Form.Check
                    id="immediateTreatmentRework"
                    required
                    checked={
                      nonConformityReport?.immediateTreatment === "rework"
                    }
                    type="radio"
                    name="immediateTreatment"
                    label={t("non-conformity-report-page.rework")}
                    value="rework"
                    onChange={(ev) => {
                      setValue("immediateTreatment", ev.target.value);
                    }}
                  />
                  <InputGroup>
                    <Form.Check
                      id="immediateTreatmentOther"
                      required
                      checked={
                        nonConformityReport?.immediateTreatment === "other"
                      }
                      type="radio"
                      name="immediateTreatment"
                      label={t("non-conformity-report-page.other")}
                      value="other"
                      onChange={(ev) => {
                        setValue("immediateTreatment", ev.target.value);
                      }}
                    />
                  </InputGroup>
                  {nonConformityReport?.immediateTreatment === "other" && (
                    <>
                      <label className="mr-3"><b>{t("non-conformity-report-page.describe")}:</b></label>
                      <Form.Control
                        id="otherImmediateTreatmentDescription"
                        as="textarea"
                        rows={1}
                        required
                        defaultValue={
                          nonConformityReport?.otherImmediateTreatmentDescription
                        }
                        onChange={(ev) => {
                          setValue(
                            "otherImmediateTreatmentDescription",
                            ev.target.value
                          );
                        }}
                      />
                    </>
                  )}
                </InputGroup>
                <Col sm={12}>
                  <div className="d-flex justify-content-start">
                    <b className="mr-2">
                      {t(
                        "non-conformity-report-page.verification-of-treatment"
                      )}
                    </b>
                    <span>{t("non-conformity-report-page.(if-required)")}</span>
                  </div>
                  <ValidInvalidFormCheck
                    value={nonConformityReport?.verifyImmediateTreatment}
                    setValue={(newValue) => {
                      setValue("verifyImmediateTreatment", newValue);
                    }}
                    trueLabel={t("non-conformity-report-page.conform")}
                    falseLabel={t("non-conformity-report-page.not-conform")}
                    id="verifyImmediateTreatmentCheck"
                  ></ValidInvalidFormCheck>
                </Col>
              </div>
              </fieldset>
            </div>
            <Row className="mb-2">
              <Col sm={12}>
                <div className="d-flex justify-content-start">
                    <b className="mr-2">
                    {t("non-conformity-report-page.is-effect-real-or-potential")}
                    </b>
                    <span>{t("non-conformity-report-page.(indicate-that-a-potential-problem-can-happen-again)")}</span>
                  </div>
                <ValidInvalidFormCheck
                  value={nonConformityReport?.isEffectRealOrPotential}
                  setValue={(newValue) => {
                    setValue("isEffectRealOrPotential", newValue);
                  }}
                  trueLabel={t("common.yes")}
                  falseLabel={t("common.no")}
                  id="isEffectRealOrPotentialCheck"
                ></ValidInvalidFormCheck>
              </Col>
            </Row>
            <Row className="mb-2">
              <Col sm={12}>
                <InputGroup>
                  <label className="mr-2">
                    <b>
                    {t(
                      "non-conformity-report-page.cost-associated-with-the-non-conformity"
                    )} :
                    </b>
                  </label>
                  <Form.Control
                    id="costAssociatedWithTheComplain"
                    as="textarea"
                    rows={1}
                    required
                    defaultValue={
                      nonConformityReport?.costAssociatedWithTheComplain
                    }
                    onChange={(ev) => {
                      setValue(
                        "costAssociatedWithTheComplain",
                        ev.target.value
                      );
                    }}
                  />
                </InputGroup>
              </Col>
            </Row>
            <Row>
              <Col sm={5}>
                <InputGroup>
                  <label>
                    <b>{t("non-conformity-report-page.corrective-action")} :</b>
                  </label>
                  <ValidInvalidFormCheck
                    value={nonConformityReport?.isCorrectiveAction}
                    setValue={(newValue) => {
                      setValue("isCorrectiveAction", newValue);
                    }}
                    trueLabel={t("common.yes")}
                    falseLabel={t("common.no")}
                    id="isCorrectiveActionCheck"
                  ></ValidInvalidFormCheck>
                </InputGroup>
              </Col>
              <Col sm={7}>
                <InputGroup>
                  <label className="mr-2">
                    <b>{t("non-conformity-report-page.corrective-action-number")} :</b>
                  </label>
                  <Form.Control
                    id="correctiveActionNumber"
                    required
                    defaultValue={nonConformityReport?.correctiveActionNumber}
                    onChange={(ev) => {
                      setValue("correctiveActionNumber", ev.target.value);
                    }}
                  />
                </InputGroup>
              </Col>
            </Row>
            <div className="card mt-2">
              <div className="card-body">
                <Row>
                  <Col sm={6} className="d-flex justify-content-between">
                    <label>{t("non-conformity-report-page.technician")}</label>
                    {(nonConformityReport?.technicianUserName
                      ? nonConformityReport?.technicianUserName
                      : "") +
                      (employee?.nom === nonConformityReport?.technicianUserName
                        ? ""
                        : t(
                            "non-conformity-report-page.save-to-become-tester",
                            {
                              technicianName: employee?.nom,
                            }
                          ))}
                  </Col>
                  <Col sm={6} className="d-flex justify-content-between">
                    <label>{t("non-conformity-report-page.supervisor")}</label>
                    {nonConformityReport?.supervisorUserName
                      ? nonConformityReport.supervisorUserName
                      : employee?.roleId && employee?.roleId <= GestionnaireRole
                      ? employee.nom +
                        t("non-conformity-report-page.close-to-confirm")
                      : t(
                          "non-conformity-report-page.supervisor-must-validate"
                        )}
                  </Col>
                  <Col sm={6} className="d-flex justify-content-between">
                    <label>{t("non-conformity-report-page.date-tested")}</label>
                    {isFinish
                      ? stringHelper.toDateString(
                          nonConformityReport?.closeDate
                        )
                      : employee?.roleId && employee?.roleId <= GestionnaireRole
                      ? stringHelper.toDateString(new Date()) +
                        t("non-conformity-report-page.close-to-confirm")
                      : t(
                          "non-conformity-report-page.supervisor-must-validate"
                        )}
                  </Col>
                </Row>
              </div>
            </div>

            <div className="no-print">
              <div className="d-flex text-center mt-3">
                <div className="flex-fill">
                  <button
                    type="button"
                    className="btn btn-outline-primary"
                    onClick={() => window.print()}
                  >
                    <FontAwesomeIcon
                      size="xl"
                      className="me-2"
                      icon={faPrint}
                    />
                    {t("common.print")}
                  </button>
                </div>
                <div className="flex-fill">
                  <button
                    type="button"
                    className="btn btn-outline-primary"
                    onClick={() => {
                      if (nonConformityReport && employeeId) {
                        nonConformityReportService
                          .saveNonConformityReport(
                            nonConformityReport,
                            employeeId
                          )
                          .then((res) => {
                            if (res) {
                              navigate(
                                `../non-conformity-report-form/${employeeId}/${res}`
                              );
                            }
                            toast.success(
                              t("non-conformity-report-page.success-saving")
                            );
                          })
                          .catch(() =>
                            toast.error(
                              t("non-conformity-report-page.error-saving")
                            )
                          );
                      }
                    }}
                  >
                    <FontAwesomeIcon
                      size="xl"
                      className="me-2"
                      icon={faFloppyDisk}
                    />
                    {t("common.save")}
                  </button>
                </div>
                {!nonConformityReport?.closeDate &&
                  employee?.roleId &&
                  employee?.roleId <= GestionnaireRole && (
                    <div className="flex-fill">
                      <button className="btn btn-outline-success" type="submit">
                        <FontAwesomeIcon
                          size="xl"
                          className="me-2"
                          icon={faCheck}
                        />
                        {t("non-conformity-report-page.close")}
                      </button>
                    </div>
                  )}
              </div>
            </div>
          </div>
        </Form>
      </div>
    </Page>
  );
  function loadNonConformityReport() {
    if (reportId)
      nonConformityReportService
        .getNonConformityReport(reportId)
        .then((res) => {
          setNonConformityReport(res);
        })
        .catch(() => {
          toast.error(
            t("non-conformity-report-page.get-non-conformity-report-page-error")
          );
        });
  }
  function loadUser(employeeId: string) {
    userService
      .getUser(employeeId)
      .then((data) => {
        if (data) {
          setEmployee(data);
        }
      })
      .catch(() => {
        toast.error(t("user-form-page.load-error"));
      });
  }
  function setValue(key: string, value: any) {
    setNonConformityReport({
      ...nonConformityReport,
      [key]: value,
    } as NonConformityReport);
  }
}
type ValidInvalidFormCheckComponentsProps = {
  value: boolean | undefined;
  setValue: (newValue: boolean | undefined) => void;
  trueLabel: string;
  falseLabel: string;
  id: string;
};
function ValidInvalidFormCheck({
  value,
  setValue,
  trueLabel,
  falseLabel,
  id,
}: ValidInvalidFormCheckComponentsProps) {
  return (
    <div className="validInvalidCheckboxContainer">
      <InputGroup>
        <Form.Check
          id={id + "-true"}
          className="mx-1"
          label={<label htmlFor={id + "-true"}>{trueLabel}</label>}
          checked={value === true}
          onChange={() => {
            let newValue = value ? undefined : true;
            setValue(newValue);
          }}
        />

        <Form.Check
          id={id + "-false"}
          reverse
                    className="mx-1"
          label={<label htmlFor={id + "-false"}>{falseLabel}</label>}
          checked={value === false}
          onChange={() => {
            let newValue = value === false ? undefined : false;
            setValue(newValue);
          }}
        />
      </InputGroup>
    </div>
  );
}
export default NonConformityReportForm;
